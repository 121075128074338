import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = props => {
  const post = props.data.markdownRemark
  const siteTitle = props.data.site.siteMetadata.title

  return (
    <Layout
      location={props.location}
      title={siteTitle}
      updatedDate={post.frontmatter.date}
    >
      <SEO title={post.frontmatter.title} />
      <article className={props.location.pathname.replace(/^\/|\/$/g, "")}>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        {post.frontmatter.anvilDetails && (
          <Link to="/customer-anvils">← Go Back</Link>
        )}
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        anvilDetails
      }
    }
  }
`
